import React, { createContext, useState, useEffect } from "react";
import { createGraphQLClient } from "@shopify/graphql-client";
import * as storefrontApi from "./storefront-api";

const client = createGraphQLClient({
  url: `https://${process.env.GATSBY_SHOPIFY_STORE_URL}/api/${process.env.GATSBY_STOREFRONT_API_VERSION}/graphql.json`,
  headers: {
    "Content-Type": "application/json",
    "X-Shopify-Storefront-Access-Token":
      process.env.GATSBY_STOREFRONT_ACCESS_TOKEN,
  },
  retries: 1,
});

const defaultValues = {
  client,
  isOpen: false,
  loading: false,
  onOpen: () => {},
  onClose: () => {},
  addVariantToCart: () => {},
  removeLineItem: () => {},
  updateLineItem: () => {},
  cart: {
    lines: {
      nodes: [],
    },
  },
};

export const StoreContext = createContext(defaultValues);

const isBrowser = typeof window !== `undefined`;
const localStorageKey = `storefront_cart_id`;

// const LIMITED_STOCK_THRESHOLD = process.env.LIMITED_STOCK_THRESHOLD || null;
export const STOCK_STATES = {
  IN_STOCK: "in_stock",
  LIMITED_STOCK: "limited_stock",
  OUT_OF_STOCK: "out_of_stock",
};

export const determineStockState = (variants, variantId, context) => {
  const { IN_STOCK, LIMITED_STOCK, OUT_OF_STOCK } = STOCK_STATES;

  // checks availability for an individual variant
  if (variantId) {
    variants = variants.filter((variant) => variant.id === variantId);

    if (variants.length > 0) {
      return variants[0].quantityAvailable ? IN_STOCK : OUT_OF_STOCK;
    }
  }

  // falls back to checking availability for a product with variants
  if (variants.length > 0) {
    const stocks = variants.map((variant) => variant.quantityAvailable > 0);
    let isAvailable = stocks.includes(true);
    let hasOutOfStock = stocks.includes(false);

    return isAvailable
      ? hasOutOfStock
        ? LIMITED_STOCK
        : IN_STOCK
      : OUT_OF_STOCK;
  }

  return IN_STOCK;
};

export const StoreProvider = ({ children }) => {
  const [cart, setCart] = useState(defaultValues.cart);
  const [loading, setLoading] = useState(false);
  const [didJustAddToCart, setDidJustAddToCart] = useState(false);

  const setCartLocalKey = (cart) => {
    if (isBrowser) {
      localStorage.setItem(localStorageKey, cart.id);
    }

    setCart(cart);
  };

  useEffect(() => {
    const initializeCart = async () => {
      const existingCartID = isBrowser
        ? localStorage.getItem(localStorageKey)
        : null;

      if (existingCartID && existingCartID !== `null`) {
        try {
          const { data } = await storefrontApi.cartRetrieve(
            client,
            existingCartID
          );
          console.log("retrieveCart", data);
          // if (!data.cart.completedAt) {
          setCartLocalKey(data.cart);
          return;
          // }
        } catch (e) {
          localStorage.setItem(localStorageKey, null);
          console.error(e);
        }
      }

      const { data } = await storefrontApi.cartCreate(client);
      console.log("newCart", data);
      setCartLocalKey(data.cartCreate.cart);
    };

    initializeCart();
  }, []);

  const addVariantToCart = (variantId, quantity, customAttributes) => {
    setLoading(true);

    console.log(variantId, quantity);

    const lineToAdd = {
      merchandiseId: variantId,
      quantity: parseInt(quantity, 10),
      // sellingPlanId: ,
    };
    if (customAttributes) {
      lineToAdd.attributes = customAttributes;
    }

    return storefrontApi
      .addLineItems(client, cart.id, [lineToAdd])
      .then(({ data }) => {
        setCart(data.cartLinesAdd.cart);
        setLoading(false);
        setDidJustAddToCart(true);
        setTimeout(() => setDidJustAddToCart(false), 5 * 1000);
      });
  };

  const removeLineItem = (cartId, lineId) => {
    setLoading(true);

    return storefrontApi
      .removeLineItems(client, cartId, [lineId])
      .then(({ data }) => {
        setCart(data.cartLinesRemove.cart);
        setLoading(false);
      });
  };

  const updateLineItem = (cartId, lineId, quantity) => {
    setLoading(true);

    const lineToUpdate = { id: lineId, quantity: parseInt(quantity, 10) };

    console.log(lineToUpdate);

    return storefrontApi
      .updateLineItems(client, cartId, lineToUpdate)
      .then(({ data }) => {
        console.log(data);
        setCart(data.cartLinesUpdate.cart);
        setLoading(false);
      });
  };

  return (
    <StoreContext.Provider
      value={{
        ...defaultValues,
        addVariantToCart,
        removeLineItem,
        updateLineItem,
        loading,
        didJustAddToCart,
        cart,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};
