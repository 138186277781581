const cartQuery = `
  id
  createdAt
  updatedAt
  checkoutUrl
  lines (first: 50) {
    nodes {
      id
      quantity
      attributes {
        key
        value
      }
      cost {
        amountPerQuantity {
          amount
          currencyCode
        }
        subtotalAmount {
          amount
          currencyCode
        }
        totalAmount {
          amount
          currencyCode
        }
      }
      merchandise {
        ... on ProductVariant {
          id
          title
          quantityAvailable
          image {
            src
            altText
          }
          metafields (identifiers: [{key: "max_quantity", namespace: "my_fields"}]) {
            id
            namespace
            key
            value
          }
          product {
            id
            title
            metafields (identifiers: [
              {
                key: "unique_item",
                namespace: "my_fields"
              },
              {
                key: "towel_material",
                namespace: "custom"
              },
              {
                key: "quilt_size",
                namespace: "my_fields"
              },
              {
                key: "quilt_dimensions",
                namespace: "custom"
              }
            ]) {
              id
              namespace
              key
              value
            }
          }
        }
      }
    }
  }
  totalQuantity
  cost {
    subtotalAmount {
      amount
      currencyCode
    }
    totalAmount {
      amount
      currencyCode
    }
  }
  discountAllocations {
    discountedAmount {
      amount
      currencyCode
    }
  }
`;

export const cartCreate = async (client) => {
  let createQuery =
    `
    mutation {
      cartCreate(input: {}) {
        cart {
          ` +
    cartQuery +
    `
        }
      }
    }
  `;

  return client.request(createQuery);
};

export const cartRetrieve = (client, cartId) => {
  let retrieveQuery =
    `
    query retrieve($cartId: ID!) {
      cart(id: $cartId) {
        ` +
    cartQuery +
    `
      }
    }
  `;

  return client.request(retrieveQuery, {
    variables: {
      cartId,
    },
  });
};

export const addLineItems = (client, cartId, lines) => {
  let addLinesQuery =
    `
    mutation ($cartId: ID!, $lines: [CartLineInput!]!) {
      cartLinesAdd(cartId: $cartId, lines: $lines) {
        cart {
          ` +
    cartQuery +
    `
        }
      }
    }
  `;

  return client.request(addLinesQuery, {
    variables: {
      cartId,
      lines,
    },
  });
};

export const removeLineItems = (client, cartId, lineIds) => {
  let removeLinesQuery =
    `
    mutation ($cartId: ID!, $lineIds: [ID!]!) {
      cartLinesRemove(cartId: $cartId, lineIds: $lineIds) {
        cart {
          ` +
    cartQuery +
    `
        }
      }
    }
  `;

  return client.request(removeLinesQuery, {
    variables: {
      cartId,
      lineIds,
    },
  });
};

export const updateLineItems = (client, cartId, lines) => {
  let updateLinesQuery =
    `
    mutation ($cartId: ID!, $lines: [CartLineUpdateInput!]!) {
      cartLinesUpdate(cartId: $cartId, lines: $lines) {
        cart {
          ` +
    cartQuery +
    `
        }
      }
    }
  `;

  return client.request(updateLinesQuery, {
    variables: {
      cartId,
      lines,
    },
  });
};

export const getProductVariantsStock = (client, id) => {
  let productQuery = `
    query getProductById($id: ID!) {
      product(id: $id) {
        variants (first: 250) {
          nodes {
            id
            quantityAvailable
          }
        }
      }
    }
  `;

  return client.request(productQuery, {
    variables: {
      id,
    },
  });
};
