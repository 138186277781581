exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cart-js": () => import("./../../../src/pages/cart.js" /* webpackChunkName: "component---src-pages-cart-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-products-shopify-product-product-type-index-js": () => import("./../../../src/pages/products/{ShopifyProduct.productType}/index.js" /* webpackChunkName: "component---src-pages-products-shopify-product-product-type-index-js" */),
  "component---src-pages-products-shopify-product-product-type-shopify-product-handle-js": () => import("./../../../src/pages/products/{ShopifyProduct.productType}/{ShopifyProduct.handle}.js" /* webpackChunkName: "component---src-pages-products-shopify-product-product-type-shopify-product-handle-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-templates-page-categories-js": () => import("./../../../src/templates/Page/Categories.js" /* webpackChunkName: "component---src-templates-page-categories-js" */),
  "component---src-templates-page-default-js": () => import("./../../../src/templates/Page/Default.js" /* webpackChunkName: "component---src-templates-page-default-js" */),
  "component---src-templates-page-faq-js": () => import("./../../../src/templates/Page/FAQ.js" /* webpackChunkName: "component---src-templates-page-faq-js" */),
  "component---src-templates-page-home-js": () => import("./../../../src/templates/Page/Home.js" /* webpackChunkName: "component---src-templates-page-home-js" */),
  "component---src-templates-policy-js": () => import("./../../../src/templates/Policy.js" /* webpackChunkName: "component---src-templates-policy-js" */),
  "component---src-templates-post-default-js": () => import("./../../../src/templates/Post/Default.js" /* webpackChunkName: "component---src-templates-post-default-js" */)
}

